<template>
  <Container class="flex items-start mt-16 mb-24 space-x-6">
    <TabGroup v-if="isDesktop"
              vertical
              :selected-index="selectedTab"
              @change="changeTab"
    >
      <TabList as="template">
        <SectionTabContainer>
          <Tab v-for="(tab, i) in tabs"
               :key="i"
               as="template"
          >
            <SectionTabButton :icon="tab.icon"
                              :title="tab.title"
                              :selected-tab="selectedTab === i"
            />
          </Tab>
        </SectionTabContainer>
      </TabList>
      <TabPanels class="p-6">
        <TabPanel v-for="(tab, i) in tabs"
                  :key="i"
        >
          <SectionTabContent :content="tab.content"
                             class="transition"
          />
        </TabPanel>
      </TabPanels>
    </TabGroup>

    <div v-else>
      <SectionTabContainer>
        <a v-for="(tab, i) in tabs"
           :key="i"
           :href="`#${slugify(tab.title, { lower: true })}`"
           class="block"
        >
          <SectionTabButton :icon="tab.icon"
                            :title="tab.title"
                            :selected-tab="selectedTab === i"
          />
        </a>
      </SectionTabContainer>
      <div>
        <SectionTabContent v-for="(tab, i) in tabs"
                           :id="slugify(tab.title, { lower: true })"
                           :key="i"
                           :content="tab.content"
                           class="pt-16 transition"
        />
      </div>
    </div>
  </Container>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import slugify from 'slugify'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import { useScreenSize } from '../../composables/use-screen-size'

const { isDesktop } = useScreenSize()

interface TabsProps {
  tabs: {
    title: string
    icon: string
    content: {
      title: string
      copy: string
      accordion: {
        title: string
        content: string
      }[]
    }
  }[]
}

const selectedTab = ref(0)

function changeTab(index: number) {
  selectedTab.value = index
}

defineProps<TabsProps>()
</script>
